/*
 * 업무구분: 고객
 * 화 면 명: MSPCM317M
 * 화면설명: 관심고객 목록 조회
 * 작 성 일: 2022.07.15
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="New Page" :show-title="false" action-type="search" @on-scroll-bottom="fn_LoadingData()" ref="page" :topButton="true" :topButtonBottom="topButtonBottom" @on-scroll="fn_OnPageScrollEvent">
    <ur-box-container direction="column" alignV="start">
      <!-- 상단고정 Tab UI -->
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <div slot="nav" class="icon-wrapper">
                <mo-icon color="white" icon-size="36px" borderless @click="fn_HistoryBack">previous</mo-icon>
              </div>
              <div slot="action" @click="fn_OpenMSPCM116P()">
                <mo-icon borderless>search</mo-icon><!-- icon-size="36px" -->
              </div>
              <!-- 헤더 타이틀 영역 -->
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '관심고객' : '관심고객'}}</div> <!-- 퍼블추가 : <div class="ui-gnb-title__main"> -->
              <!-- 헤더 타이틀 영역 //-->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div style="height: 1px"></div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === true && isObj === true">
              <mo-carousel number-of-page="2" ref="swiper" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style1">
                    <strong class="title">인수참여</strong>
                    <strong class="title" v-if="hasPtpt">신청이 완료되었습니다.</strong>
                    <strong class="title" v-else>신청 기간입니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" v-if="hasPtpt" @click="fn_ConfInsu()">인수참여 취소</mo-button>
                      <mo-button class="ns-btn-round white" v-else @click="fn_Insu('I')">인수참여 신청</mo-button>
                    </div>
                  </div>
                </template>
                <template #2>
                  <div class="ns-banner-box ns-style2">
                    <strong class="title">회수대상</strong>
                    <strong class="title">고객이 있습니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" @click="fn_MoveNextTab()">회수제외 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === false && isObj === true">
              <mo-carousel number-of-page="1" ref="swiper1" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style1">
                    <strong class="title">인수참여</strong>
                    <strong class="title" v-if="hasPtpt">신청이 완료되었습니다.</strong>
                    <strong class="title" v-else>신청 기간입니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" v-if="hasPtpt" @click="fn_ConfInsu()">인수참여 취소</mo-button>
                      <mo-button class="ns-btn-round white" v-else @click="fn_Insu('I')">인수참여 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === true && isObj === false">
              <mo-carousel number-of-page="1" ref="swiper2" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style2">
                    <strong class="title">회수대상</strong>
                    <strong class="title">고객이 있습니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" @click="fn_MoveNextTab()">회수제외 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box default-idx="1" primary class="ns-move-tab-box">
              <!-- tab id loop -->
              <mo-tab-label class="on" idx="1">전체</mo-tab-label>
              <mo-tab-label class="off" idx="2" @click="fn_MoveNextTab()">회수대상</mo-tab-label>
              <!-- tab id loop //-->
            </mo-tab-box>

            <!-- 전체체크 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckboxAllClick()" :disabled="checkDisabled || isEmptyView"></mo-checkbox>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">{{ checkedCnt > 0 ? checkedCnt + '명 선택' : '총 ' + totCnt + '명' }}</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- 조회결과 리스트 -->
            <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-accodian ns-style2">
                <mo-list :list-data="cmCccObjrCmpxSVO">
                  <template #list-item="{ index, item }">
                    <mo-list-item expandable prevent-expand :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                      <mo-checkbox v-model="item.checked" @input="fn_CheckboxClick()" :disabled="checkDisabled" :ref="`chk${index}`"></mo-checkbox>
                      <div class="list-item__contents">
                        <!-- 고객명/회수제외 뱃지 -->
                        <div class="list-item__contents__title">
                          <!-- 고객카드 없음 240129 추가 -->
                          <span class="name" :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" @click.stop="fn_OpenCustInfoBs(item)">
                            {{ item.custNm }}
                          </span>
                          <!-- 고객카드 없음 240129 추가 -->
                          <div v-if="item.colStrNm === '회수제외'"><mo-badge class="badge-sample-badge blue" :text="item.colStrNm" shape="status">{{item.colStrNm}}</mo-badge></div>
                        </div>
                        <!-- 미터치상태/회수대상 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span>미터치 기간 : </span><span class="txtRed ml10">{{item.touchstate}}{{item.ltmUnTuchWdrwObjYn ? ' / ' + item.ltmUnTuchWdrwObjYn : ''}}</span>
                          </div>
                        </div>
                        <!-- 터치내용/최근터치일 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span>터치이력 : </span><span class="ml10">{{item.custCntcIdCd}}</span><span class="ml10">{{item.rntTuchYmd}}</span>
                          </div>
                        </div>
                      </div>
                      <template v-slot:expand class="ns-gray-box">
                        <div class="list-item-detail customer-interest">
                          <!-- 생년월일/성별/마케팅동의 -->
                          <div class="contents-row">
                            <div class="info-cell">
                              <span>{{item.dob}}</span><em>|</em>
                              <span>{{item.sxdsCdNm}}</span><em>|</em>
                              <span>{{item.mktCnsntEndYn}}</span>
                            </div>
                          </div>
                          <!-- 고객카드 YN -->
                          <div class="contents-row">
                            <div class="title-cell w100">
                              <span>고객카드</span>
                            </div>
                            <div class="value-cell">
                              <span>{{ item.custYn }}</span>
                            </div>
                          </div>
                          <!-- 인수일 -->
                          <div class="contents-row table">
                            <div class="title-cell w100">
                              <span>인수일</span>
                            </div>
                            <div class="value-cell">
                              <span>{{item.undtkYmd}}</span>
                            </div>
                          </div>
                          <!-- 원거리회수신청 : 신청한 경우에만 노출 -->
                          <div class="contents-row table" v-if="item.colStrNm === '원거리'">
                            <div class="title-cell w100">
                              <span>원거리회수신청</span>
                            </div>
                            <div class="value-cell">
                              <span></span>
                            </div>
                          </div>
                          <!-- 회수제외신청 : 신청한 경우에만 노출 -->
                          <div class="contents-row table" v-if="item.rmtchHestYmd">
                            <div class="title-cell w100">
                              <span>회수제외신청</span>
                            </div>
                            <div class="value-cell">
                              <span>{{item.rmtchHestYmd}}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>

              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
              <div v-if="positionUp && cmCccObjrCmpxSVO.length > 0" class="ns-height120"></div>
              <div v-else-if="cmCccObjrCmpxSVO.length > 0" class="ns-height40"></div>

            </ur-box-container>

            <!-- NoData 영역  -->
            <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <!-- 개인정보 이용 및 활용 동의 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>고객카드 생성</template>
              <div class="customer-info-txt pt0">
                <strong class="name">{{ pObjmsg.custNm }}</strong>
                <p class="info">생년월일 
                  <span class="birth">{{ $bizUtil.dateDashFormat(pObjmsg.birthDay) }}</span><em>|</em>
                  <span>{{ pObjmsg.gender }}</span>
                </p>
                <p class="ment">
                  등록된 고객이 아닙니다.<br>
                  '고객카드 생성'버튼을 클릭하시면<br>
                  고객카드가 생성됩니다.<br>
                  (단, 필수컨설팅, 마케팅동의는 별도 수집 필요)
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Btn_C_OnClick()">취소</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 원거리/회수제외신청 버튼 -->
            <div :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'">
              <mo-bottom-sheet ref="nsbottomSheet2">
                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" :class="btnState.indexOf('1') >= 0 && btnState.indexOf('3') >= 0 ? 'ns-btn-round white' : 'ns-btn-round blue'"
                        v-if="btnState.indexOf('1') >= 0" @click="fn_OnDefaultModal2('D')">원거리 회수</mo-button>
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
                        v-if="btnState.indexOf('2') >= 0" @click="fn_OnDefaultModal2('D1')">원거리 회수 신청취소</mo-button>
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" :class="btnState.indexOf('1') >= 0 && btnState.indexOf('3') >= 0 ? 'ns-btn-round white' : 'ns-btn-round blue'"
                        v-if="btnState.indexOf('3') >= 0" @click="fn_OnDefaultModal3('U')">회수제외</mo-button>
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
                        v-if="btnState.indexOf('4') >= 0" @click="fn_OnDefaultModal3('D')">회수제외 신청취소</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
            </div>

            <!-- 원거리회수신청 확인 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_orgnDst" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>원거리 회수</template>
              <div class="customer-info-txt">
                <p v-if="btnState.indexOf('1') >= 0" class="ment gray">
                  고객별로 실제 회수 가능 여부/회수 소요기간은 상이할 수 있습니다
                </p>
                <p v-else-if="btnState.indexOf('2') >= 0" class="ment gray">
                  원거리 회수 신청을 취소합니다. 신청하시겠습니까?
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnDefaultModal2('cancel')">취소</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnDefaultModal2('confirm')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 회수제외신청 확인 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_rMtchHest" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>회수제외 신청</template>
              <div class="customer-info-txt">
                <p v-if="rMtchHestFlag === 'U'" class="ment gray">
                  회수제외를 신청하시겠습니까?
                </p>
                <p v-else class="ment gray">
                  회수제외 신청을 취소하시겠습니까?
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnDefaultModal5('cancel')">아니요</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnDefaultModal5('confirm')">예</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 인수참여신청 취소 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_confirmInsu" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>인수참여신청</template>
              <div class="customer-info-txt">
                <p class="ment gray">
                  인수참여신청을 취소하시겠습니까? 취소하시면 이달 신규 관심고객이 제공되지 않습니다.
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseInsu()">취소</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Insu('C')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************///
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 수금인수고객 목록 검색
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM317M',
  // 현재 화면 ID
  screenId: 'MSPCM317M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPCM116P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      // 배너 옵션
      swiperOption: {
        centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
          delay: 20000,
          disableOnInteraction: false, 
        }
      },
      lv_HeaderviewScrollCompID: '',

      // 누적 총 조회건수
      totCnt: 0,
      // 전체 체크
      isOneOrMoreCheck: false,
      // bottom sheet class
      positionUp: false,
      // 신청버튼 상태 (1,2,3,4)
      btnState: '',
      
      // YYYYMMDD
      today: Number,
      // 데이터 없음 화면
      isEmptyView: false,
      // 지점장 여부
      isMngr: false,
      // 최근 조직 조회 데이터
      pOrgData317M: {},
      // 사용자 정보
      userInfo: {},
      // 터치순위코드
      tuchRnkCdKey: '',
      // 인수코드
      cccDtScCdKey: '00',
      // 회수유예대상코드
      rmtchObjYnCdKey: '',
      // 고객명
      inputCustNm: '',
      // 최근활동(터치)일
      lstUnTuchPrdCdKey: ' ',
      // 더보기 기능
      isLoading: false,
      // 터치순위
      tuchRnkCds: [],
      // 인수구분
      cccDtScCds: [],
      // 최근활동내역
      activityCd: [],

      lv_inputParam: {
        cnsltNo: '', // 컨설턴트번호
        inqrScVal: '' // 조회구분코드
      },
      // 서비스호출 파라미터(원거리회수)
      lv_inputParamD: {
        iCCccDistVO: []
      },
      // 서비스호출 파라미터(회수대상 유예신청)
      lv_inputParamP: {
        iCWdrwHestAplVO: []
      },

      // 회수제외신청/취소 flag
      rMtchHestFlag: '',
      // 인수대상인 경우, 인수자격
      addmInfoCntnt: '',
      // 더보기 최초조회
      stndKeyVal: 'start',
      // 초기로드 or 조회버튼시
      stndKeyList: [],
      // 페이지 카운트
      pageRowCnt: '20',
      // 인피니트 바인딩 리스트VO
      cmCccObjrCmpxSVO: [],
      // 회수대상유무
      hasUnTuch: false,
      // 인수대상여부
      isObj: false,
      // 인수신청여부
      hasPtpt: false,
      
      // 고객카드 생성 변수
      pObjmsg: { // 고객카드 생성 Obj
        cnsltNo: '', // 컨설턴트번호(ZA_CHNL_ID)
        custId: '', // 고객ID(PARTNER)
        custNm: '', // 고객명
        knb: '', // 주민번호
        birthDay: '', // 생년월일
        gender: '' // 성별
      },

      isSearched: false, // 조회이력 저장 변수
      checkDisabled: false, // 체크박스 비활성화 여부
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems () {
      return this.cmCccObjrCmpxSVO.filter(item => item.checked === true)
    },
    checkedCnt () {
      return this.cmCccObjrCmpxSVO.filter(item => item.checked).length
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let lv_vm = this

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, lv_vm.fn_HistoryBack)

    // 사용자 정보
    lv_vm.userInfo = lv_vm.getStore('userInfo').getters.getUserInfo
    lv_vm.today = Number(moment(new Date()).format('YYYYMMDD'))

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if (lv_vm.$cmUtil.isSrchOrgCombo() === 'Y') {
      lv_vm.isMngr = true
    }

    // 공통코드 조회
    this.$commonUtil.selListMassSliCommCd(['TUCH_RNK_CD', 'CCC_DT_SC_CD', 'CUST_CNTC_ID_CD'])
      .then(function () {
        let codeList1 = lv_vm.getStore('code').getters.getState.get('TUCH_RNK_CD') // 터치순위 - 사용안함
        let codeList2 = lv_vm.getStore('code').getters.getState.get('CCC_DT_SC_CD') // 인수구분
        let codeList3 = lv_vm.getStore('code').getters.getState.get('CUST_CNTC_ID_CD') // 최근활동내역

        if (codeList1 !== undefined && codeList1 !== null && codeList1.length !== 0) {
          if (codeList2 !== undefined && codeList2 !== null && codeList2.length !== 0) {
            if (codeList3 !== undefined && codeList3 !== null && codeList3.length !== 0) {
              lv_vm.tuchRnkCds = lv_vm.$bizUtil.parseSegmentHeadCode(codeList1, '', '전체')
              lv_vm.cccDtScCds = codeList2
              lv_vm.activityCd = codeList3

              // 해당 설계사 인수대상여부/참여여부/자격 조회
              lv_vm.fn_SearchPtptQulf(true, true, false)
            }
          }
        }
      })
  },

  activated () {
    // 화면 스크롤을 위한 코드
    let sp = this.scrollPosition || 0
    if(sp > 10) {
      sp = sp - Math.ceil(sp*0.19)
    }
    this.$refs.page.scrollTo(sp)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview?.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    // page(tab) view count
    let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM317M

    // 관심고객 메인을 들어온 상태에서 다른 화면을 갔다온 경우 배제
    if (pageCnt === 0) {
      // 최초접근
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', -1)
    } else {
      // 관심고객 회수대상에서 넘어온 경우를 제외하곤 카운트 쌓지 않음
      if (pageCnt % 2 === -0) {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', pageCnt - 1)
      }
    }

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM317M')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkedCnt > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData317M = {} // 조직 조회 데이터
          this.inputCustNm = '' // 고객명
          this.cccDtScCdKey = '00' // 인수
          this.lstUnTuchPrdCdKey = ' ' // 최근터치일
  
          this.isSearched = false // 검색이력 초기화
  
          // 바텀시트 초기화
          if (this.$refs !== undefined) {
            if (this.$refs.nsbottomSheet2 !== undefined) {
              this.positionUp = false
              this.$refs.nsbottomSheet2.close()
            }
          }
  
          // 재조회
          this.fn_SearchPtptQulf(true, true, false)
        } else {
          // 탭이 아닌 별도 페이지로 구성되어 store에서 누적 카운트를 한다.
          let cnt = window.vue.getStore('cmStore').getters.getState.viewCntCM317M
          window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', 0)
          // 조회를 하지 않았으면 뒤로가기
          // 23-05-23 메뉴 리스트에서 선택 가능한 화면에서 back 버튼 선택 시 홈 화면으로 이동 김미정 프로
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_MoveNextTab
     * 설명       : 회수대상 탭 이동
     ******************************************************************************/
    fn_MoveNextTab (tabIdx) {
      this.$router.push({name: 'MSPCM320D'})
    },

    /******************************************************************************
     * Function명 : fn_CheckboxClick
     * 설명       : 체크박스 클릭
     ******************************************************************************/
    fn_CheckboxClick() {
      if(this.cmCccObjrCmpxSVO.length === this.checkedCnt){
        this.isOneOrMoreCheck = true
      }else{
        this.isOneOrMoreCheck = false
      }

      this.fn_CtrlBottomSheet()
    },

    /******************************************************************************
     * Function명 : fn_CheckboxAllClick
     * 설명       : 체크박스 전체 클릭
     ******************************************************************************/
    fn_CheckboxAllClick() {
      this.$nextTick(() => {
        if (this.cmCccObjrCmpxSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cmCccObjrCmpxSVO) {
            if (this.isOneOrMoreCheck) {
              this.cmCccObjrCmpxSVO[n].checked = true
            } else {
              this.cmCccObjrCmpxSVO[n].checked = false
            }
          }

          // 체크 시 BottomSheet 컨트롤
          this.fn_CtrlBottomSheet()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CtrlBottomSheet
     * 설명       : 바텀시트 컨트롤
     ******************************************************************************/
    fn_CtrlBottomSheet () {
      this.fn_CtrlBottomSheetDetail()
      if (this.checkedCnt === 0) {
        this.positionUp = false
        this.$refs.nsbottomSheet2.close()
      } else {
        if (this.checkedCnt === 1) {
          this.positionUp = true
          this.$refs.nsbottomSheet2.open()
        } else if (this.checkedCnt > 1) {
          if (this.btnState !== '0') {
            this.positionUp = true
            this.$refs.nsbottomSheet2.open()
          } else {
            this.positionUp = false
            this.$refs.nsbottomSheet2.close()
          }
        } else {
          this.positionUp = false
          this.$refs.nsbottomSheet2.close()
        }
      }

      // 고객 체크 시 BottomSheet
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /******************************************************************************
     * Function명 : fn_CtrlBottomSheetDetail
     * 설명       : 바텀시트 컨트롤 세부
     ******************************************************************************/
    fn_CtrlBottomSheetDetail () {
      let temp2Cnt = 0
      let temp4Cnt = 0
      let temp13Cnt = 0
      let temp1Cnt = 0
      for (let i = 0; i < this.selectItems.length; i++) {
        if (this.selectItems[i].orgnDstCustWdrwAplYn.trim() === 'Y') {
          //원거리 신청을 했으면
          temp2Cnt++
        } else {
          // 원거리 신청을 안했으면
          if (this.selectItems[i].rmtchHestYmd.trim() !== '') {
            // 회수제외 신청했으면
            temp4Cnt++
          } else {
            // 회수제외 신청안했으면
            if (this.selectItems[i].touchstate.indexOf('12') >= 0) {
              // 회수대상이면
              temp13Cnt++
            } else {
              // 회수대상이 아니면
              temp1Cnt++
            }
          }
        }
      }

      // bottom sheet 버튼 조건
      if ( this.selectItems.length === temp2Cnt ) {
        this.btnState = '2' // 원거리회수 신청 취소
      } else if (this.selectItems.length === temp4Cnt) {
        this.btnState = '4' // 회수제외 신청 취소
      } else if (this.selectItems.length === temp13Cnt) {
        this.btnState = '13' // 원거리회수 신청 + 회수제외 신청
      } else if (this.selectItems.length === temp1Cnt) {
        this.btnState = '1' // 원거리 회수 신청
      } else {
        this.btnState = '0' // 없음
      }
    },

    /******************************************************************************
    * function명  : fn_LoadingData
    * 설명        : 대상 조회(loading시)
    ******************************************************************************/
    fn_LoadingData () {
      // 리스트 존재시에만 작동
      if (!this.isLoading && this.cmCccObjrCmpxSVO.length > 0) {

        // 더보기 시 전체체크 해제되어있으면 전체체크 해제 후 조회
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
        
        // 대상 조회
        this.fn_GetServiceS()
      }
    },

    /******************************************************************************
    * Function명 : fn_SearchPtptQulf
    * 설명       : 해당 설계사 인수대상여부/참여여부/자격 조회
    ******************************************************************************/
    fn_SearchPtptQulf (flag, submit, getservice) {
      let lv_vm = this
      let pParams = {}
      const trnstId = 'txTSSCM54S3'// 인수자격 조회
      const auth = 'S'

      pParams.cnsltNo = lv_vm.userInfo.userId // 컨설턴트번호
      pParams.inqrScVal = 'S' // 조회구분

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let objYn = response.body.objYn // 인수대상여부
            let ptptYn = response.body.ptptYn // 인수참여여부

            if (objYn === 'Y') {
              if (ptptYn === 'Y') {
                // 대상여부 y 참여여부 y
                lv_vm.isObj = true
                lv_vm.hasPtpt = true
              } else {
                // 대상여부 y 참여여부 n
                lv_vm.isObj = true
                lv_vm.hasPtpt = false
              }
              lv_vm.addmInfoCntnt = response.body.addmInfoCntnt
            } else {
              // 대상여부 n 참여여부 n
              lv_vm.isObj = false
              lv_vm.hasPtpt = false
            }
          } else {
            lv_vm.$showSnackbar(response.msgComm.msgDesc)
          }
          // 회수대상조회
          lv_vm.fn_GetServiceUnTuch(flag, submit, getservice)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_GetServiceUnTuch
    * 설명       : 서비스 호출(회수대상 관심고객 목록 조회)
    ******************************************************************************/
    fn_GetServiceUnTuch (flag, submit, getservice) {
      let lv_vm = this
      const auth = 'S'
      // const trnstId = 'txTSSCM54S1'// 관심고객 목록 조회
      const trnstId = 'txTSSCM47S3'// 관심고객 목록 조회

      let pParams = {}
      pParams.cnsltNo = this.userInfo.userId // 컨설턴트번호
      pParams.tuchRnkCd = this.tuchRnkCdKey // 터치순위코드
      pParams.undtkScCd = this.cccDtScCdKey // 인수코드
      pParams.ltmUnTuchWdrwObjYn = this.rmtchObjYnCdKey // 회수유예대상코드
      pParams.custNm = this.inputCustNm // 고객명
      // 1년이상
      pParams.rntTuchInqrStrYmd = ''
      pParams.rntTuchInqrEndYmd = ''
      pParams.ltmUnTuchWdrwObjYn = 'Y'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCCccObjrMoblVO

            // 회수대상유무
            if(responseList !== undefined && responseList !== null && responseList.length > 0){
              lv_vm.hasUnTuch = true
            }else{
              lv_vm.hasUnTuch = false
            }

            // 배너 swiper 적용되지 않아 강제로 업데이트
            if (lv_vm.$refs !== undefined && lv_vm.$refs.swiper !== undefined) {
              lv_vm.$refs.swiper.$forceUpdate();
            }
            if (lv_vm.$refs !== undefined && lv_vm.$refs.swiper1 !== undefined) {
              lv_vm.$refs.swiper1.$forceUpdate();
            }
            if (lv_vm.$refs !== undefined && lv_vm.$refs.swiper2 !== undefined) {
              lv_vm.$refs.swiper2.$forceUpdate();
            }

            // 배너 조정
            lv_vm.$refs.headerview?.adjustHeight()

            if ( flag === true ) {
              if ( submit === true ) {
                lv_vm.fn_SubmitS()
              } else if ( getservice === true ) {
                // 현 로직상 이건 안탐
                lv_vm.fn_GetServiceS()
              }
            }
          } else {
            lv_vm.$showSnackbar(response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_SubmitS
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_SubmitS () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData317M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData317M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.cmCccObjrCmpxSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부

      this.fn_GetServiceS() // 서비스 호출
    },

    /******************************************************************************
      * Function명 : fn_GetServiceS
      * 설명       : 서비스 호출(관심고객 목록 조회)
    ******************************************************************************/
    fn_GetServiceS (str) {
      if (this.stndKeyVal === 'end') return
      if (this.isLoading) return

      let lv_vm = this
      const auth = 'S'
      // const trnstId = 'txTSSCM54S1'// 관심고객 목록 조회
      const trnstId = 'txTSSCM47S3'// 관심고객 목록 조회

      let pParams = {}
      // 지점장 조회시 구분
      if (this.pOrgData317M.cnslt !== undefined && this.isMngr === true) {
        pParams.cnsltNo = this.pOrgData317M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        pParams.cnsltNo  = this.userInfo.userId // 컨설턴트 번호
      }
      pParams.tuchRnkCd = this.tuchRnkCdKey // 터치순위코드
      pParams.undtkScCd = this.cccDtScCdKey // 인수코드
      pParams.ltmUnTuchWdrwObjYn = this.rmtchObjYnCdKey // 회수유예대상코드
      pParams.custNm = this.inputCustNm // 고객명

      // 오늘
      let nowDate = this.$bizUtil.getDateToString(new Date(), '')

      if (this.lstUnTuchPrdCdKey === '2') {
        // 1개월이내
        pParams.rntTuchInqrStrYmd = this.$bizUtil.getDateToString(new Date(moment(new Date()).add(-1, 'months')), '')
        pParams.rntTuchInqrEndYmd = nowDate
      } else if (this.lstUnTuchPrdCdKey === '3') {
        // 6개월이내
        pParams.rntTuchInqrStrYmd = this.$bizUtil.getDateToString(new Date(moment(new Date()).add(-6, 'months')), '')
        pParams.rntTuchInqrEndYmd = nowDate
      } else if (this.lstUnTuchPrdCdKey === '4') {
        // 1년이상
        pParams.rntTuchInqrStrYmd = ''
        pParams.rntTuchInqrEndYmd = ''
        pParams.ltmUnTuchWdrwObjYn = 'Y'
      } else {
        // 전체
        pParams.rntTuchInqrStrYmd = ''
        pParams.rntTuchInqrEndYmd = ''
      }

      this.isLoading = true
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCCccObjrMoblVO

            lv_vm.lv_inputParamD.cnsltNo = response.body.cnsltNo
            lv_vm.lv_inputParamD.strYmd = response.body.orgnDstWdrwAplStrYmd
            lv_vm.lv_inputParamD.endYmd = response.body.orgnDstWdrwAplEndYmd
            lv_vm.lv_inputParamD.rMtchHestAplStrYmd = response.body.rmtchHestAplStrYmd
            lv_vm.lv_inputParamD.rMtchHestAplEndYmd = response.body.rmtchHestAplEndYmd
            lv_vm.lv_inputParamP.finYm = response.body.finYm
            lv_vm.rmtchHestAplFgr = response.body.rmtchHestAplFgr

            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].disabled = lv_vm.disabled
               
                // 오늘
                let nowDate = lv_vm.$bizUtil.getDateToString(new Date(), '')
                let month1Date = ''
                let month6Date = ''
                let month12Date = ''

                if (responseList[i].rntTuchYmd !== undefined && responseList[i].rntTuchYmd !== null && responseList[i].rntTuchYmd !== '' && responseList[i].rntTuchYmd.trim() !== '') {
                  // 최근터치일 3개월 경과
                  month1Date = lv_vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+3, 'months')), '')
                  // 최근터치일 6개월 경과
                  month6Date = lv_vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+6, 'months')), '')
                  // 최근터치일 12개월 경과
                  month12Date = lv_vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+1, 'years')), '')
                }

                responseList[i].touchstate = ''
                if (month1Date !== '' && nowDate > month1Date) {
                  responseList[i].touchstate = '3개월'
                }
                if (month6Date !== '' && nowDate > month6Date) {
                  responseList[i].touchstate = '6개월'
                }
                if (month12Date !== '' && nowDate > month12Date) {
                  responseList[i].touchstate = '12개월'
                }

                var activity_commCd = lv_vm.activityCd.filter(varm => varm.cdVal === responseList[i].custCntcIdCd)
                if ( activity_commCd.length > 0) {
                  responseList[i].custCntcIdCd = activity_commCd[0].cdValNm
                }
                // 회수대상 초기화
                responseList[i].ltmUnTuchWdrwObjYn = ''

                // 회수대상-임의코딩
                // 최근터치일-12개월 => '대상'
                // 최근터치일-6개월 => '유의'
                // 최근터치일 공란, 인수일-12개월 => '대상'
                if (responseList[i].rntTuchYmd.trim() === '') {
                  if (responseList[i].undtkYmd.trim() !== '') {
                    // 인수일 12개월 경과
                    let undtkYmd12Date = lv_vm.$bizUtil.getDateToString(new Date(moment(responseList[i].undtkYmd).add(+1, 'years')), '')
                    if ( nowDate > undtkYmd12Date) {
                      responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                      responseList[i].touchstate = '12개월'
                    }
                  } else { 
                    responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                    responseList[i].touchstate = '12개월'
                  }
                } else {
                  if (responseList[i].touchstate === '12개월') {
                    responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                  } else if (responseList[i].touchstate === '6개월') {
                    responseList[i].ltmUnTuchWdrwObjYn = '유의'
                  }
                }
                // 회수대상-임의코딩 끝
                // 고객카드 Y/N
                if (responseList[i].chnlCustId.trim() !== '') {
                  responseList[i].custYn = 'Y'
                } else {
                  responseList[i].custYn = 'N'
                }

                responseList[i].dob = lv_vm.$commonUtil.dateDashFormat(responseList[i].dob)
                responseList[i].rntTuchYmd = lv_vm.$commonUtil.dateDashFormat(responseList[i].rntTuchYmd) // 최근터치일
                responseList[i].mktCnsntEndYmd = lv_vm.$commonUtil.dateDashFormat(responseList[i].mktCnsntEndYmd)
                responseList[i].undtkYmd = lv_vm.$commonUtil.dateDashFormat(responseList[i].undtkYmd)
                responseList[i].rmtchHestYmd = lv_vm.$commonUtil.dateDashFormat(responseList[i].rmtchHestYmd.trim())
                
                if (responseList[i].orgnDstCustWdrwAplYn === 'Y') { // 두개가 동시에 "Y" 가 될 확률 없음
                  responseList[i].colStrType = '원거리회수신청'
                  responseList[i].colStrNm = '원거리'
                }else if (responseList[i].rmtchHestYmd.trim() !== '') {
                  responseList[i].colStrType = '회수제외신청'
                  responseList[i].colStrNm = '회수제외'
                } else {
                  responseList[i].colStrType = ''
                  responseList[i].colStrNm = ''
                }

                if(responseList[i].mktCnsntEndYmd.trim() === ''){
                  responseList[i].mktCnsntEndYn = '마동 N'
                }else{
                  responseList[i].mktCnsntEndYn = '마동 Y'
                }

                responseList[i].ck = false
                responseList[i].checked = false
              }

              // 더보기 키값
              lv_vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_vm.stndKeyList === null || lv_vm.stndKeyList[0] === null || lv_vm.stndKeyList[0].stndKeyVal === null || lv_vm.stndKeyList[0].stndKeyVal.trim() === '') {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_vm.stndKeyVal = 'end'//
              } else {
                // 다음페이지 데이터 존재함
                lv_vm.stndKeyVal = 'more'
              }

              if (lv_vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_vm.cmCccObjrCmpxSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_vm.cmCccObjrCmpxSVO = responseList
              }
              // 조회 총 카운트
              lv_vm.totCnt = lv_vm.cmCccObjrCmpxSVO.length

              if (responseList.length === 0) {
                lv_vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_vm.isEmptyView = false // 데이터가 없습니다 visible false
              }
            } else {
              lv_vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_vm.isEmptyView = true // 데이터가 없습니다 visible true
            lv_vm.$showSnackbar(response.msgComm.msgDesc)
          }
          lv_vm.isLoading = false
        })
        .catch(function (error) {
          lv_vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_Insu
     * 설명       : 관심고객 인수참여
     ******************************************************************************/
    fn_Insu (sendType) {
      let lv_vm = this
      let pParams = {}
      // 인수참여,취소
      if (sendType === 'I' || sendType === 'C') { 
        const trnstId = 'txTSSCM54U3'
        const auth = 'S'

        let confirm = this.getStore('confirm')

        pParams.cnsltNo = lv_vm.userInfo.userId // 컨설턴트번호
        pParams.inqrScVal = sendType // 인수참여,취소

        this.post(lv_vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
              if (sendType === 'I') {
                lv_vm.hasPtpt = true
              } else {
                lv_vm.hasPtpt = false
              }

              // 인수 취소시 바텀시트 닫기
              if (sendType === 'C') {
                lv_vm.$refs.bottomSheet_confirmInsu.close()
              }

              // 해당 설계사 인수대상여부/참여여부/자격 조회
              lv_vm.fn_SearchPtptQulf(false, false, false)
            } else {
              lv_vm.$showSnackbar(response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },

    /******************************************************************************
     * Function명 : fn_ConfInsu
     * 설명       : 관심고객 인수참여 취소 확인
     ******************************************************************************/
    fn_ConfInsu () {
      this.$refs.bottomSheet_confirmInsu.open()
    },

    /******************************************************************************
     * Function명 : fn_CloseInsu
     * 설명       : 관심고객 인수참여 취소 확인
     ******************************************************************************/
    fn_CloseInsu () {
      this.$refs.bottomSheet_confirmInsu.close()
    },

    /******************************************************************************
     * Function명 : fn_CheckValue
     * 설명       : 유효성 검사
     ******************************************************************************/
    fn_CheckValue (serviceType) {
      let returnValue = true

      if (serviceType === 'D') {
        let startD = Number(this.lv_inputParamD.strYmd)
        let endD = Number(this.lv_inputParamD.endYmd)

        if (this.today >= startD && this.today <= endD) {
          returnValue = true
        } else {
          this.$showSnackbar('원거리회수 신청기간이 아닙니다.')
          returnValue = false
        }
      } else if (serviceType === 'D2') {
      }
      return returnValue
    },

    /******************************************************************************
     * Function명 : fn_OnDefaultModal2
     * 설명       : 원거리 회수 신청/취소
     ******************************************************************************/
    fn_OnDefaultModal2 (str) {
      if (str === 'confirm') {
        if (this.btnState.indexOf('1') >= 0) { // 신청
          if (this.fn_CheckValue('D')) {
            this.fn_DataSetD('D')
            this.fn_GetServiceD('D')
          } else {
            return
          }
        } else if (this.btnState.indexOf('2') >= 0) { // 취소
          this.fn_DataSetD('D1')
          this.fn_GetServiceD('D1')
        }
      } else if (str === 'D') { // 원거리 신청 확인
        let distCheck = false
        for (let i in this.selectItems) {
          if (this.selectItems[i].rmtchHestYmd !== '') {
            this.getStore('confirm').dispatch('ADD', '회수제외신청 취소 후 원거리회수 신청바랍니다.')
            distCheck = true
            break
          }
        }
        if (distCheck) {
          return
        }
      } else if (str === 'cancel') {
        this.$refs.bottomSheet_orgnDst.close()
        return
      }

      // 원거리회수신청/취소 확인 바텀시트 오픈
      this.$refs.bottomSheet_orgnDst.open()
    },

    /******************************************************************************
     * Function명 : fn_DataSetD
     * 설명       : 원거리회수 전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSetD (type) {
      let cnt = this.selectItems.length
      for (let i = 0; i < cnt; i++) {
        let saveObj = {}
        if (this.pOrgData317M.cnslt !== undefined && this.isMngr === true) {
          saveObj.cnsltNo = this.pOrgData317M.cnslt.key // 컨설턴트번호
        } else {
          saveObj.cnsltNo = this.userInfo.userId
        }
        saveObj.chnlCustId = this.selectItems[i].custId

        if (type === 'D') {
          saveObj.orgnDstCustWdrwAplYn = 'Y'
        } else if (type === 'D1') {
          saveObj.orgnDstCustWdrwAplYn = 'N'
        }
        this.lv_inputParamD.iCCccDistVO.push(saveObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_GetServiceD
     * 설명       : 서비스 호출(원거리회수)
     ******************************************************************************/
    fn_GetServiceD (str) {
      // const trnstId = 'txTSSCM54U1'// 원거리 회수
      const trnstId = 'txTSSCM47U4'// 원거리 회수
      const auth = 'S'

      let lv_vm = this
      let pParams = this.lv_inputParamD
      let confirm = this.getStore('confirm')
      if (this.isLoading) return
      this.isLoading = true
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_vm.isLoading = false
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            if (str === 'D') {
              // 원거리회수 신청
              lv_vm.$showSnackbar('원거리 회수 신청되었습니다.')
            } else {
              // 원거리회수 취소
              lv_vm.$showSnackbar('원거리 회수신청 취소되었습니다.')
            }

            lv_vm.fn_SubmitS() // 재조회

            // 바텀시트 초기화
            lv_vm.positionUp = false
            lv_vm.$refs.nsbottomSheet2.close()
            lv_vm.$refs.bottomSheet_orgnDst.close()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_OnDefaultModal3
     * 설명       : 회수제외신청/취소 선택
     ******************************************************************************/
    fn_OnDefaultModal3 (str) {
      this.rMtchHestFlag = str
      let startD = Number(this.lv_inputParamD.rMtchHestAplStrYmd)
      let endD = Number(this.lv_inputParamD.rMtchHestAplEndYmd)
      let conCheck = false

      // if (!(this.today >= startD && this.today <= endD)) { // pass
      if (this.today >= startD && this.today <= endD) { // 원본
        if (this.rMtchHestFlag === 'U') {
          for (let i in this.selectItems) {
            if (this.selectItems[i].orgnDstCustWdrwAplYn === 'Y') {
              this.getStore('confirm').dispatch('ADD', '원거리회수신청 취소 후 회수제외신청바랍니다.')
              conCheck = true
              break
            }
            if (this.selectItems[i].rmtchHestYmd !== '') {
              this.getStore('confirm').dispatch('ADD', '신청 고객과 미신청 고객이 같이 선택될 수 없습니다')
              conCheck = true
              break
            }
          }
          if (conCheck) {
            return
          } else {
            let selCnt = Number(this.rmtchHestAplFgr) + Number(this.selectItems.length)
            if (selCnt > 20) {
              this.getStore('confirm').dispatch('ADD', '이번 달 회수제외 가능 고객수가 초과되었습니다 (' + selCnt + '/20)')
              return
            }
          }
        } else {
          for (let i in this.selectItems) {
            if (this.selectItems[i].rmtchHestYmd === '') {
              this.getStore('confirm').dispatch('ADD', '신청 고객과 미신청 고객이 같이 선택될 수 없습니다')
              conCheck = true
              break
            }
          }
          if (conCheck) {
            return
          }
        }
      } else {
        this.$showSnackbar('회수제외 신청기간이 아닙니다')
        return
      }

      // 회수제외신청/취소 확인 바텀시트 오픈
      this.$refs.bottomSheet_rMtchHest.open()
    },

    /******************************************************************************
     * Function명 : fn_OnDefaultModal5
     * 설명       : 회수제외신청/취소 확인 바텀시트
     ******************************************************************************/
    fn_OnDefaultModal5 (str) {
      if (str === 'confirm') {
        this.fn_DataSetU()
        this.fn_GetServiceU(this.rMtchHestFlag)
      } else {
        // 취소 버튼
        this.$refs.bottomSheet_rMtchHest.close()
      }
    },

    /******************************************************************************
     * Function명 : fn_DataSetU
     * 설명       : 회수제외신청 전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSetU () {
      this.lv_inputParamP.iCWdrwHestAplVO = []
      let cnt = this.selectItems.length

      for (let i = 0; i < cnt; i++) {
        let saveObj = {}
        // 지점장 조회시 구분
        if (this.pOrgData317M.cnslt !== undefined && this.isMngr === true) {
          saveObj.cnsltNo = this.pOrgData317M.cnslt.key // 컨설턴트번호
        } else {
          saveObj.cnsltNo = this.userInfo.userId
        }
        saveObj.custId = this.selectItems[i].custId
        saveObj.finYm = this.lv_inputParamP.finYm
        this.lv_inputParamP.iCWdrwHestAplVO.push(saveObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_GetServiceU
     * 설명       : 서비스 호출(회수제외신청)
     ******************************************************************************/
    fn_GetServiceU (type) {
      let trnstId = 'txTSSCM54U4'// 회수제외신청
      if (type === 'D') {
        trnstId = 'txTSSCM54U5'// 회수제외취소
      }
      const auth = 'S'

      let lv_vm = this
      let pParams = this.lv_inputParamP
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_vm.isLoading = false

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            if (type === 'D') {
              lv_vm.$showSnackbar('회수제외 신청 취소가 완료되었습니다.')
            } else {
              lv_vm.$showSnackbar('회수제외 신청이 완료되었습니다.')
            }

            // 바텀시트 초기화
            lv_vm.positionUp = false
            lv_vm.$refs.nsbottomSheet2.close()
            lv_vm.$refs.bottomSheet_rMtchHest.close()

            lv_vm.fn_SubmitS() // 재조회
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_OpenInsCustInfo
     * 설명       : 고객카드 생성 팝업
     ******************************************************************************/
    fn_OpenInsCustInfo (custInfo) {
      if (custInfo.rrn === undefined) {
        this.$showSnackbar('생년월일이 없습니다.')
      } else {
        this.pObjmsg = {
          cnsltNo: this.userInfo.userId, // 컨설턴트번호(ZA_CHNL_ID)
          custId: custInfo.custId, // 고객ID(PARTNER)
          custNm: custInfo.custNm, // 고객명
          knb: custInfo.rrn, // 주민번호
          birthDay: this.$bizUtil.addBirthFrontYear(custInfo.rrn.substring(0, 6)), // 생년월일
          gender: (custInfo.sxdsCdNm !== undefined) ? custInfo.sxdsCdNm : this.fn_GetSxdsNmByRrn(custInfo) // 성별
        }

        // 고객카드생성 바텀시트 오픈
        this.$refs.bottomSheet_CustInfo.open()
      }
    },

    /******************************************************************************
     * Function명 : fn_GetSxdsNmByRrn
     * 설명       : 주민번호로 성별 구하기
     ******************************************************************************/
    fn_GetSxdsNmByRrn (custInfo) {
      let rtnVal = ''
      let sexDist = custInfo.rrn.substring(6,7)

      if (sexDist === '1' || sexDist === '3' || sexDist === '5' || sexDist === '7') {
        rtnVal = '남'
      } else if (sexDist === '2' || sexDist === '4' || sexDist === '6' || sexDist === '8') {
        rtnVal = '여'
      }

      return rtnVal
    },

    /******************************************************************************
     * Function명 : fn_Btn_C_OnClick
     * 설명       : 고객카드생성 취소 버튼
     ******************************************************************************/
    fn_Btn_C_OnClick () {
      // 고객카드생성 바텀시트 닫기
      this.$refs.bottomSheet_CustInfo.close()
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        this.fn_InsCustInfo()
      }
    },

    /******************************************************************************
     * Function명 : fn_InsCustInfo
     * 설명       : 통합고객 정보를 채널고객으로 등록
     ******************************************************************************/
    fn_InsCustInfo () {
      const lv_Vm = this
      const trnstId = 'txTSSCM55I1'
      const auth = 'I'

      let confirm = lv_Vm.getStore('confirm')
      let iCNewCustRegVO = lv_Vm.fn_SET_OCC() // 고객 저장 데이터 셋팅
      let pParams = iCNewCustRegVO

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '' && response.body.chnlCustId !== null && response.body.chnlCustId !== '') {
            // 고객카드 저장 완료 후 바텀시트 닫고
            lv_Vm.$refs.bottomSheet_CustInfo.close()
            lv_Vm.positionUp = false
            lv_Vm.$refs.nsbottomSheet2.close()
            // 리스트 재조회
            lv_Vm.fn_SubmitS()
            // 네임UI 오픈
            let custInfo = {
              chnlCustId: response.body.chnlCustId,
              custNm: ''
            }
            lv_Vm.fn_OpenCustInfoBs(custInfo)
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_SET_OCC
     * 설명       : 고객 저장 데이터 셋팅
     ******************************************************************************/
    fn_SET_OCC () {
      let param = {}

      param.prcsfCd = 'I'
      param.condWhlCnt = 0
      param.cnsltNo = this.pObjmsg.cnsltNo
      param.custId = this.pObjmsg.custId
      param.custNm = this.pObjmsg.custNm
      param.knb = this.pObjmsg.knb
      param.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220) *** 신태블릿용 추가 검토 필요
      param.trtSystmPathCd = '22' // 처리시스템경로코드(사랑온 고정 구분값 : 22) *** 신태블릿용 추가 검토 필요
      param.custDtlTypCd = this.fn_USR_getNatyGb(this.pObjmsg.knb) // 고객상세유형코드 셋팅

      if (this.pObjmsg.knb.length > -6) {
        param.chnlCustScCd = '01'
        param.ralDob = this.$bizUtil.addBirthFrontYear(this.pObjmsg.knb.substring(0, 6))
      } else {
        param.chnlCustScCd = '02'
        param.ralDob = ' '
      }

      param.iCNewCustRegListVO = [{
        prcsfCd : param.prcsfCd,
        chnlCustScCd : param.chnlCustScCd,
        custId : param.custId,
        custNm : param.custNm,
        knb : this.pObjmsg.knb,
        cnsltNo : param.cnsltNo,
        ralDob : param.ralDob,
        mnCustRltnCd : "01",
        mnCustRltnCdNm : "본인"
      }]

      return param
    },

    /******************************************************************************
     * Function명 : fn_USR_getNatyGb
     * 설명       : 고객상세유형코드 셋팅
     ******************************************************************************/
    fn_USR_getNatyGb (knb) {
      var natyGb = '11'
      if (knb.length < 7) {
        return natyGb
      }
      var knbGb = knb.substring(6, 7)

      if (knbGb === '1' || knbGb === '2' || knbGb === '3' || knbGb === '4') {
        natyGb = '11'
      } else if (knbGb === '5' || knbGb === '6' || knbGb === '7' || knbGb === '8') {
        natyGb = '12'
      }
      return natyGb
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM317M',
          pOrgData: this.pOrgData317M, // 최근 조직 조회 데이터
          pSearchKeyword: this.inputCustNm, // 고객명
          pIsMngr: this.isMngr, // 지점장 여부
          pCccDtScCd: this.cccDtScCdKey, // 인수(default 전체)
          pLstUnTuchPrdCd: this.lstUnTuchPrdCdKey // 최근터치일
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.positionUp = false
            this.$refs.nsbottomSheet2.close()
            this.$bottomModal.close(this.popup116)

            this.inputCustNm = pData.rtnData.searchKeyword // 고객명
            this.cccDtScCdKey = pData.rtnData.cccDtScCd // 인수
            this.lstUnTuchPrdCdKey = pData.rtnData.lstUnTuchPrdCd // 최근터치일

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData317M = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 재조회
            this.fn_SubmitS()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
      if(this.$bizUtil.isEmpty(item.chnlCustId)) {
        this.fn_OpenInsCustInfo(item)
        return
      }
      
      if(this.pOrgData317M.cnslt === undefined || this.pOrgData317M.cnslt === null || this.pOrgData317M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData317M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM317M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.isOneOrMoreCheck = false
              lv_Vm.fn_CheckboxAllClick()

              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData317M = {} // 조직 조회 데이터
                this.inputCustNm = '' // 고객명
                this.cccDtScCdKey = '00' // 인수
                this.lstUnTuchPrdCdKey = ' ' // 최근터치일

                this.isSearched = false // 검색이력 초기화

                // 바텀시트 초기화
                if (this.$refs !== undefined) {
                  if (this.$refs.nsbottomSheet2 !== undefined) {
                    this.positionUp = false
                    this.$refs.nsbottomSheet2.close()
                  }
                }

                // 재조회
                this.fn_SearchPtptQulf(true, true, false)
              } else {
                // 탭이 아닌 별도 페이지로 구성되어 store에서 누적 카운트를 한다.
                let cnt = window.vue.getStore('cmStore').getters.getState.viewCntCM317M
                window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', 0)
                // 조회를 하지 않았으면 뒤로가기
                // 23-05-23 메뉴 리스트에서 선택 가능한 화면에서 back 버튼 선택 시 홈 화면으로 이동 김미정 프로
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_OnPageScrollEvent
     * 설명       : 화면 스크롤 컨트롤
     ******************************************************************************/
    fn_OnPageScrollEvent(e) {
      let target = e.target
      if(!target) return
      let sp = target.scrollTop
      this.scrollPosition = sp
    }

  }
}
</script>